import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { FaArrowRight } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { FaBookmark } from "react-icons/fa";
import { Helmet } from "react-helmet";
import FixedEnquiry from "../../Components/fixed compoents/FixedEnquiry";
import FixedWhatsapp from "../../Components/fixed compoents/FixedWhatsapp";
import StudentEnquiry from "../../Components/fixed compoents/StudentEnquiry";
import { Link } from "react-router-dom";
export const Singapore = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>
          Study in Singapore | Top Canada Universities, Courses, Fees & Visa
          Support
        </title>
        <meta name="author" content="https://afynd.com/" />
        <meta
          name="description"
          content="We take care from Application to Acceptance. Our Overseas Education services include admission procedure, documentation, VISA assistance, Education Loan assistance from a reputed bank, pre/post departure service."
        />
        <meta
          name="keywords"
          content="Application to Acceptance, Overseas Education, admission procedure, VISA assistance, Education Loan assistance, pre/post departure service"
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="assets/images/edufynd-fav-logo"
        />
        <link rel="manifest" href="assets/img/favicons/manifest.php" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="assets/img/favicons/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&amp;family=Jost:wght@300;400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <FixedEnquiry />
      <FixedWhatsapp />
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row ">
          <div className="col-12">
            <div class="card text-bg-dark rounded-0 ">
              <img
                src="https://www.eduthrive.org/wp-content/uploads/2023/07/desktop-wallpaper-study-abroad-abroad.jpg"
                class="card-img img-fluid "
                style={{ maxHeight: "23rem", mixBlendMode: "multiply" }}
                alt="admission_image"
              />
              <div class="card-img-overlay align-self-end">
                <div className="text-uppercase text-white text-center fs-2 fw-bold">
                  Study In Singapore
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                  <div>
                    <Link
                      to=""
                      href="/"
                      className="text-decoration-none text-white fs-5"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>
                  <div>
                    <Link
                      to=""
                      href="/StudyDestination"
                      className="text-decoration-none text-white fs-5"
                    >
                      Study Destinations
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>

                  <div className="text-white fs-5"> Study In Singapore</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-9 ">
            <div class="card p-3">
              <img
                class="card-img-top rounded-0 h-100  rounded-2 "
                src="https://www.applyboard.com/wp-content/uploads/2022/01/USA_BlogPost-1024x538.png"
                alt="..."
              />
              <div class="card-body">
                <h4
                  class="card-title fw-bold py-2"
                  style={{ color: "#0f2239" }}
                >
                  Study in Singapore
                </h4>
                <p
                  class="card-text fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Choose Singapore for an exceptional education system, obtain
                  globally recognized qualifications from top-ranked
                  institutions, and immerse yourself in a multicultural society
                  with an outstanding quality of life.{" "}
                </p>
                <p
                  class="card-text fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  A compelling feature is the relatively shorter duration of
                  most master's courses, typically spanning just one year.
                  Tuition fees vary, averaging around EUR 35,000 for
                  international students. Securing an education loan for the
                  Singapore emerges as an excellent financial strategy to cover
                  tuition fees and living expenses.
                </p>
                <p
                  class="card-text fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Furthermore, these loans play a crucial role as proof of funds
                  when applying for Singapore student visas, streamlining the
                  visa application process.{" "}
                </p>
              </div>
            </div>
            <div className="border-0 rounded-2 shadow-sm my-4">
              <ul
                class="nav nav-underline rounded-0  fs-9"
                id="myTab"
                role="tablist"
                style={{ fontSize: "12px" }}
              >
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link text-uppercase active fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#tab-home"
                    role="tab"
                    aria-controls="tab-home"
                    aria-selected="false"
                    tabindex="-1"
                  >
                    {" "}
                    <span>
                      <FaBookmark />
                    </span>{" "}
                    why choose Singapore
                  </a>
                </li>
                <li class="nav-item " role="presentation">
                  <a
                    class="nav-link text-uppercase  fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#tab-profile"
                    role="tab"
                    aria-controls="tab-profile"
                    aria-selected="false"
                    tabindex="-1"
                  >
                    <span>
                      <FaBookmark />
                    </span>
                    benefits of Singapore
                  </a>
                </li>
                <li class="nav-item  " role="presentation">
                  <a
                    class="nav-link text-uppercase  fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="contact-tab"
                    data-bs-toggle="tab"
                    href="#tab-contact"
                    role="tab"
                    aria-controls="tab-contact"
                    aria-selected="true"
                  >
                    <span>
                      <FaBookmark />
                    </span>
                    Top courses in Singapore
                  </a>
                </li>
                <li class="nav-item  " role="presentation">
                  <a
                    class="nav-link  text-uppercase  fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="about-tab"
                    data-bs-toggle="tab"
                    href="#about-contact"
                    role="tab"
                    aria-controls="about-contact"
                    aria-selected="true"
                  >
                    <span>
                      <FaBookmark />
                    </span>
                    cost of living
                  </a>
                </li>
              </ul>
              <div
                class="tab-content rounded-0 border-0 mt-3"
                id="myTabContent"
              >
                <div
                  class="tab-pane fade show active p-4"
                  id="tab-home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Why to choose Singapore ?
                  </h4>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Rising as Asia's premier international study hub, Singapore,
                    a city of skyscrapers, attracts students globally.
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>
                    Renowned for academic excellence, its universities
                    consistently rank high globally.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Serving as the IT & Business hub of Asia, Singapore offers a
                    dynamic environment.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    The city excels in student satisfaction, safety, and
                    affordability, making it a coveted study destination.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    As an economic powerhouse with diverse job opportunities,
                    Singapore's international repute in education reinforces its
                    status as a sought-after global study destination.{" "}
                  </p>
                </div>
                <div
                  class="tab-pane fade p-4"
                  id="tab-profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Benefits of Studying in Singapore
                  </h4>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Ranked as the second-best country for living and working in
                    the 2019 HSBC ranking, this nation boasts two universities
                    within the global top 20.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>
                    Serving as a key financial hub globally, it houses
                    institutions affiliated with leading international
                    universities in the USA, the Singapore, Canada, and
                    Australia.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Students enjoy transfer opportunities to the parent campus,
                    one-year master's programs, and bachelor's programs spanning
                    2 or 3 years.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    The diverse internship options, coupled with affordable
                    tuition and living expenses, make it an attractive
                    destination, with numerous scholarships available.{" "}
                  </p>
                </div>
                <div
                  class="tab-pane fade p-4 "
                  id="tab-contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Top Courses to Study in the Singapore
                  </h4>
                  <div className="d-flex flex-row align-items-start justify-content-between gap-5">
                    <div className="d-flex flex-column ">
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        Computer Science & Information Technology{" "}
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>
                        Engineering & Science
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        Business & Management{" "}
                      </p>
                    </div>
                    <div className="d-flex flex-column ">
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>
                        Economics & Finance{" "}
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>
                        Law
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>
                        Design (Fashion, Interior, Game) Management
                      </p>
                    </div>
                    <div className="d-flex flex-column "></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade p-4 "
                  id="about-contact"
                  role="tabpanel"
                  aria-labelledby="about-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Cost of Living and Studying in the Singapore
                  </h4>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>
                    The cost of education and living in the Singapore is
                    influenced by the university and program you choose. Here's
                    a breakdown of the yearly fees for studying in the Singapore
                    and estimated monthly expenses:
                  </p>

                  <h5 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Yearly Fees for Studying in The Singapore
                  </h5>

                  <table class="table table-responsive  table-bordered">
                    <tbody>
                      <tr style={{ backgroundColor: "#FF9993" }}>
                        <th scope="col">Courses</th>
                        <th scope="col">Cost</th>
                      </tr>

                      <tr>
                        <td>Bachelor’s degrees</td>
                        <td>2S$51,840 - S$115,350</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Master’s degree</td>
                        <td>S$46,080 - S$113,884</td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Monthly Expenses of Staying in The Singapore
                  </h5>

                  <table class="table table-responsive  table-bordered">
                    <tbody>
                      <tr style={{ backgroundColor: "#FF9993" }}>
                        <th scope="col">Living Expenses</th>
                        <th scope="col">Cost</th>
                      </tr>

                      <tr>
                        <td>Accommodation</td>
                        <td>SGD 500 - SGD 1500</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Transportation</td>
                        <td>SGD 50 - SGD 150</td>
                      </tr>
                      <tr>
                        <td>Utilities</td>
                        <td>SGD 100 - SGD 200</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Food</td>
                        <td>SGD 300 - SGD 600</td>
                      </tr>
                      <tr>
                        <td>Study Material</td>
                        <td>SGD 50 - SGD 200</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Health Insurance</td>
                        <td>SGD 100 - SGD 200</td>
                      </tr>
                      <tr>
                        <td>Miscellaneous Expenses</td>
                        <td>SGD 200 - SGD 500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <ul class="list-group list-group-flush border rounded-2 p-4">
              <h4 className="text-center py-2">Countries</h4>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-USA"
                  className="text-decoration-none "
                  style={{ color: "#fe5722" }}
                >
                  Study in USA
                </Link>
              </li>
             
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-UK"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in UK
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Canada"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Canada
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Australia"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Australia
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Ireland"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Ireland
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-New-Zealand"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in New Zealand
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Europe"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Europe
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-France"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in France
                </Link>
              </li>
              
              <li
                class="list-group-item  active border-0 py-3"
                style={{ backgroundColor: "#fe5722" }}
              >
                <Link
                  to="/Study-In-Signapore"
                  className="text-decoration-none text-white"
                  style={{ backgroundColor: "#fe5722" }}
                >
                 Study in Singapore
                </Link>
              </li>
            </ul>
            <StudentEnquiry />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <h4
            className="text-center fw-bold text-uppercase"
            style={{ color: "#fe5722" }}
          >
            RELATED COUNTRIES
          </h4>
          <h2 className="text-center fw-bold " style={{ color: "#0f2239" }}>
            {" "}
            <span style={{ fontWeight: "bolder" }}>O</span>ther{" "}
            <span style={{ fontWeight: "bolder" }}>S</span>tudy{" "}
            <span style={{ fontWeight: "bolder" }}>B</span>road{" "}
            <span style={{ fontWeight: "bolder" }}>D</span>estination
          </h2>

          <section class="slide-option my-5">
            <div id="infinite" class="highway-slider">
              <div class="container highway-barrier">
                <ul class="highway-lane">
                  <li class="highway-car">
                    <div
                      class="card shadow rounded-0"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://adm.dookinternational.com/dook/images/country/XYwKYrjh1656324286.jpg"
                        class="card-img-top rounded-0 h-100 "
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in UK</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.commonwealthfund.org/sites/default/files/styles/countries_hero_desktop/public/country_image_Canada.jpg?h=f2fcf546&itok=tfGdKLUg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Canada</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.cnn.com/api/v1/images/stellar/prod/191011110307-02-australia-beautiful-places.jpg?q=w_1110,c_fill"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Australia
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.timeout.com/images/105230283/750/422/image.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Ireland</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.citigroup.com/rcs/v1/siteIds/citigpa/asset/63895c15d2b14b2b67154008.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in New Zealand
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://t4.ftcdn.net/jpg/02/94/27/73/360_F_294277354_ev3qw00wjlHAfhqRdEozsrVRpbhixC3S.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Singapore
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDIRg4bzPx84UPDDUW5o9bmWVTKxguqDyD3A&s"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in France</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.visa.co.uk/dam/VCOM/regional/ve/unitedkingdom/in-page-images/visa-in-europe/visa-eu-header-france-1600x900.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Europe</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="highway-lane">
                  <li class="highway-car">
                    <div
                      class="card shadow rounded-0"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://adm.dookinternational.com/dook/images/country/XYwKYrjh1656324286.jpg"
                        class="card-img-top rounded-0 h-100 "
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in UK</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.commonwealthfund.org/sites/default/files/styles/countries_hero_desktop/public/country_image_Canada.jpg?h=f2fcf546&itok=tfGdKLUg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Canada</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.cnn.com/api/v1/images/stellar/prod/191011110307-02-australia-beautiful-places.jpg?q=w_1110,c_fill"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Australia
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.timeout.com/images/105230283/750/422/image.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Ireland</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.citigroup.com/rcs/v1/siteIds/citigpa/asset/63895c15d2b14b2b67154008.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in New Zealand
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://t4.ftcdn.net/jpg/02/94/27/73/360_F_294277354_ev3qw00wjlHAfhqRdEozsrVRpbhixC3S.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Singapore
                        </h5>

                        <Link
                          to="#"
                          class="btn"
                          style={{ backgroundColor: "#fe5722", color: " #fff" }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDIRg4bzPx84UPDDUW5o9bmWVTKxguqDyD3A&s"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in France</h5>

                        <Link
                          to="#"
                          class="btn"
                          style={{ backgroundColor: "#fe5722", color: " #fff" }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.visa.co.uk/dam/VCOM/regional/ve/unitedkingdom/in-page-images/visa-in-europe/visa-eu-header-france-1600x900.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Europe</h5>

                        <Link
                          to="#"
                          class="btn"
                          style={{ backgroundColor: "#fe5722", color: " #fff" }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Singapore;
