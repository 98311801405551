import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { FaArrowRight } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import FixedEnquiry from "../../Components/fixed compoents/FixedEnquiry";
import FixedWhatsapp from "../../Components/fixed compoents/FixedWhatsapp";
import { Link } from "react-router-dom";
export const Admission = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title> Overseas Education Service in Chennai to Study Abroad</title>
        <meta name="author" content="https://afynd.com/" />
        <meta
          name="description"
          content="We take care from Application to Acceptance. Our Overseas Education services include admission procedure, documentation, VISA assistance, Education Loan assistance from a reputed bank, pre/post departure service."
        />
        <meta
          name="keywords"
          content="Application to Acceptance, Overseas Education, admission procedure, VISA assistance, Education Loan assistance, pre/post departure service"
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="assets/images/edufynd-fav-logo"
        />
        <link rel="manifest" href="assets/img/favicons/manifest.php" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="assets/img/favicons/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&amp;family=Jost:wght@300;400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <FixedEnquiry />
      <FixedWhatsapp />

      <div className="container-fluid p-0 overflow-hidden">
        <div className="row ">
          <div className="col-12">
            <div class="card text-bg-dark rounded-0 ">
              <img
                src="https://www.eduthrive.org/wp-content/uploads/2023/07/desktop-wallpaper-study-abroad-abroad.jpg"
                class="card-img img-fluid "
                style={{ maxHeight: "23rem", mixBlendMode: "multiply" }}
                alt="admission_image"
              />
              <div class="card-img-overlay align-self-end">
                <div className="text-uppercase text-white text-center fs-2 fw-bold">
                  ADMISSION SUPPORT
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                  <div>
                    <Link
                      to=""
                      href="/"
                      className="text-decoration-none text-white fs-5"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>
                  <div>
                    <Link
                      to=""
                      href="/Service"
                      className="text-decoration-none text-white fs-5"
                    >
                      Services
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>
                  <div className="text-white fs-5">Admission Support</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <h5
            className="text-start text-uppercase fw-semibold "
            style={{ color: "#fe5722" }}
          >
            {" "}
            OUR SERVICES
          </h5>
          <h1 className="text-start fw-bold">Admission Support</h1>
          <div className="col-md-7">
            <p className="text-muted py-2" style={{ textAlign: "justify" }}>
              At EduFynd, our commitment is to empower you with the tools and
              support needed to embark on your educational journey seamlessly.
              Your success is our priority, and we're here to ensure you make a
              confident and informed start.At EduFynd, our comprehensive
              admission support goes beyond the conventional, ensuring you're
              equipped for success at every step of your academic journey. We're
              here to guide you through a seamless and personalized experience,
              covering a spectrum of essential services to kickstart your
              educational pursuit:
            </p>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-column ">
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Test Preparation{" "}
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>
                  Program Selection
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  University Research{" "}
                </p>
              </div>
              <div className="d-flex flex-column ">
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Interview Preparation{" "}
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>
                  SOP and LOR Feedback
                </p>
                <p
                  class=" fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Application Fee Assistance
                </p>
              </div>
              <div className="d-flex flex-column "></div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="h-100 text-center">
              <img
                src="https://st2.depositphotos.com/39091214/48280/i/450/depositphotos_482803198-stock-photo-word-admission-written-wooden-cubes.jpg"
                alt=""
                className="img-fluid  h-100 mx-auto d-block "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row g-5">
          <div className="col-md-4">
            <div className=" card h-100 position-relative   border-1 shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Test Preparation
                </h5>
                <p
                  className="card-text clearfix"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Sharpen your academic skills with specialized test preparation
                  to enhance your performance.
                </p>
                <div className="position-absolute top-100 start-50 translate-middle py-2  ">
                  <Link
                    to="/"
                    className="btn  btn-sm text-uppercase fw-semibold px-4 py-2-sm text-uppercase fw-semibold px-4 py-2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                      fontSize: "12px",
                    }}
                  >
                    Check Now
                    <span className=" ">
                      {" "}
                      <FaArrowRightLong />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative   border-1 shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold "
                  style={{ color: "#0f2239" }}
                >
                  Program Selection
                </h5>
                <p
                  className="card-text clearfix"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Tailor your education to your goals through expert guidance on
                  choosing the right program.
                </p>
                <div className="position-absolute top-100 start-50 translate-middle py-2  ">
                  <Link
                    to="/Program"
                    className="btn btn btn-sm text-uppercase fw-semibold px-4 py-2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Check Now
                    <span className=" ">
                      {" "}
                      <FaArrowRightLong />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative   border-1 shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  University Research
                </h5>
                <p
                  className="card-text clearfix"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Navigate the vast landscape of universities with ease, backed
                  by thorough research and insights.
                </p>
                <div className="position-absolute top-100 start-50 translate-middle py-2  ">
                  <Link
                    to="/University"
                    className="btn btn btn-sm text-uppercase fw-semibold px-4 py-2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Check Now
                    <span className=" ">
                      {" "}
                      <FaArrowRightLong />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative   border-1 shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Interview Preparati0n
                </h5>
                <p
                  className="card-text clearfix"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Approach interviews with confidence through targeted
                  preparation and practice sessions.
                </p>
                <div className="position-absolute top-100 start-50 translate-middle py-2  ">
                  <Link
                    to="/ELT"
                    className="btn btn btn-sm text-uppercase fw-semibold px-4 py-2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Check Now
                    <span className=" ">
                      {" "}
                      <FaArrowRightLong />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative   border-1 shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  SOP and LOR Feedback
                </h5>
                <p
                  className="card-text clearfix"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Craft compelling Statements of Purpose (SOP) and Letters of
                  Recommendation (LOR) with constructive feedback to showcase
                  your unique strengths.
                </p>
                <div className="position-absolute top-100 start-50 translate-middle py-2  ">
                  <Link
                    to="/SOP-Crafting"
                    className="btn btn btn-sm text-uppercase fw-semibold px-4 py-2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Check Now
                    <span className=" ">
                      {" "}
                      <FaArrowRightLong />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" card h-100 position-relative   border-1 shadow-sm  p-3">
              <div className="card-body ">
                <h5
                  className="card-title text-center fw-bold"
                  style={{ color: "#0f2239" }}
                >
                  Application Fee Assistance
                </h5>
                <p
                  className="card-text clearfix"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  Facilitate the application process with financial support,
                  ensuring that nothing stands in the way of your educational
                  aspirations.
                </p>
                <div className="position-absolute top-100 start-50 translate-middle py-2  ">
                  <Link
                    to="/Forex"
                    className="btn btn btn-sm text-uppercase fw-semibold px-4 py-2"
                    style={{
                      backgroundColor: "#fe5722",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Check Now
                    <span className=" ">
                      {" "}
                      <FaArrowRightLong />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Admission;
