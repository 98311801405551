export const StudnetEnquiry ="studentEnquiry";
export const Loan ="loan";
export const Forex ="forex";
export const Accommodation ="accommodation";
export const Flight ="flight";
export const Contact ="generalEnquiry";
export const Program ="program";
export const University ="university";
export const SuperAdmin ="superadmin";
export const DialCode ="dialCode";
export const Blog ="blog";