import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { FaArrowRight } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { FaBookmark } from "react-icons/fa";
import { Helmet } from "react-helmet";
import FixedEnquiry from "../../Components/fixed compoents/FixedEnquiry";
import FixedWhatsapp from "../../Components/fixed compoents/FixedWhatsapp";
import StudentEnquiry from "../../Components/fixed compoents/StudentEnquiry";
import { Link } from "react-router-dom";
export const Ireland = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>
          Study in Ireland | Top Canada Universities, Courses, Fees & Visa
          Support
        </title>
        <meta name="author" content="https://afynd.com/" />
        <meta
          name="description"
          content="We take care from Application to Acceptance. Our Overseas Education services include admission procedure, documentation, VISA assistance, Education Loan assistance from a reputed bank, pre/post departure service."
        />
        <meta
          name="keywords"
          content="Application to Acceptance, Overseas Education, admission procedure, VISA assistance, Education Loan assistance, pre/post departure service"
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="assets/images/edufynd-fav-logo"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="assets/images/edufynd-fav-logo"
        />
        <link rel="manifest" href="assets/img/favicons/manifest.php" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="assets/img/favicons/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&amp;family=Jost:wght@300;400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <FixedEnquiry />
      <FixedWhatsapp />
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row ">
          <div className="col-12">
            <div class="card text-bg-dark rounded-0 ">
              <img
                src="https://www.eduthrive.org/wp-content/uploads/2023/07/desktop-wallpaper-study-abroad-abroad.jpg"
                class="card-img img-fluid "
                style={{ maxHeight: "23rem", mixBlendMode: "multiply" }}
                alt="admission_image"
              />
              <div class="card-img-overlay align-self-end">
                <div className="text-uppercase text-white text-center fs-2 fw-bold">
                  Study In Ireland
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center gap-3">
                  <div>
                    <Link
                      to=""
                      href="/"
                      className="text-decoration-none text-white fs-5"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>
                  <div>
                    <Link
                      to=""
                      href="/StudyDestination"
                      className="text-decoration-none text-white fs-5"
                    >
                      Study Destinations
                    </Link>
                  </div>
                  <div className="text-white">
                    <FaArrowRight />
                  </div>

                  <div className="text-white fs-5"> Study In Ireland</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-9 ">
            <div class="card p-3">
              <img
                class="card-img-top rounded-0 h-100  rounded-2 "
                src="https://www.applyboard.com/wp-content/uploads/2022/01/USA_BlogPost-1024x538.png"
                alt="..."
              />
              <div class="card-body">
                <h4
                  class="card-title fw-bold py-2"
                  style={{ color: "#0f2239" }}
                >
                  Study in Ireland
                </h4>
                <p
                  class="card-text fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  The number of Indian students pursuing education in Ireland
                  has reached a peak. A significant allure for students is
                  Ireland's provision of post-study work opportunities extending
                  for two years.{" "}
                </p>
                <p
                  class="card-text fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Dublin, the capital city, hosts major tech giants such as
                  Google, Facebook, Microsoft, and LinkedIn, establishing their
                  European headquarters and presenting promising job prospects.
                  Irish universities are renowned for their quality bachelor’s,
                  master’s, and MBA programs.{" "}
                </p>
                <p
                  class="card-text fw-semibold"
                  style={{ color: "#0f2239", textAlign: "justify" }}
                >
                  {" "}
                  <span className="pe-2" style={{ color: "#fe5722" }}>
                    <FaCircleCheck />
                  </span>{" "}
                  Considering an education loan for Ireland can alleviate
                  financial concerns, allowing you to focus on realizing your
                  Irish dream!{" "}
                </p>
              </div>
            </div>
            <div className="border my-4">
              <ul
                class="nav nav-pills rounded-0 fs-9"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item px-1" role="presentation">
                  <a
                    class="nav-link text-uppercase active fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#tab-home"
                    role="tab"
                    aria-controls="tab-home"
                    aria-selected="false"
                    tabindex="-1"
                  >
                    {" "}
                    <span>
                      <FaBookmark />
                    </span>{" "}
                    why choose Ireland
                  </a>
                </li>
                <li class="nav-item  px-1" role="presentation">
                  <a
                    class="nav-link text-uppercase  fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#tab-profile"
                    role="tab"
                    aria-controls="tab-profile"
                    aria-selected="false"
                    tabindex="-1"
                  >
                    <span>
                      <FaBookmark />
                    </span>
                    benefits of Ireland
                  </a>
                </li>
                <li class="nav-item  px-1" role="presentation">
                  <a
                    class="nav-link text-uppercase  fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="contact-tab"
                    data-bs-toggle="tab"
                    href="#tab-contact"
                    role="tab"
                    aria-controls="tab-contact"
                    aria-selected="true"
                  >
                    <span>
                      <FaBookmark />
                    </span>
                    Top courses in Ireland
                  </a>
                </li>
                <li class="nav-item  px-1" role="presentation">
                  <a
                    class="nav-link  text-uppercase  fw-bold p-3 rounded-0"
                    style={{ color: "#fe5722" }}
                    id="about-tab"
                    data-bs-toggle="tab"
                    href="#about-contact"
                    role="tab"
                    aria-controls="about-contact"
                    aria-selected="true"
                  >
                    <span>
                      <FaBookmark />
                    </span>
                    cost of living
                  </a>
                </li>
              </ul>
              <div class="tab-content mt-3" id="myTabContent">
                <div
                  class="tab-pane fade show active p-4"
                  id="tab-home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Why to Choose Ireland ?
                  </h4>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    As the headquarters for 9 out of 10 global ICT companies, 8
                    out of the top 10 global pharmaceutical companies, a major
                    player in financial services, and a center of international
                    banking excellence, Ireland stands as the European hub for
                    over 1,000 leading multinational corporations spanning
                    various industries.{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>
                    Studying in Ireland presents a highly lucrative opportunity
                    with potential career paths in renowned global giants such
                    as Microsoft, Google, PayPal, Apple, Twitter, LinkedIn,
                    Pfizer, GSK, and Genzyme..{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    The high academic standards of Irish universities empower
                    their graduates to make significant contributions in
                    academia, research, and the global business arena.{" "}
                  </p>
                </div>
                <div
                  class="tab-pane fade p-4"
                  id="tab-profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Benefits of Studying in Ireland
                  </h4>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    The quickest expanding economy in Europe{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>
                    Global ranking of #10 for high-quality scientific research{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Post-study work visa extended up to 2 years{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    All universities globally positioned within the top 5%{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Ranked as the 13th most peaceful location on Earth{" "}
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>
                    Qualifications quality assured by Quality & Qualifications
                    Ireland, an Irish State Agency
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Among the top 20 education systems worldwide
                  </p>
                  <p
                    class=" fw-semibold"
                    style={{ color: "#0f2239", textAlign: "justify" }}
                  >
                    {" "}
                    <span className="pe-2" style={{ color: "#fe5722" }}>
                      <FaCircleCheck />
                    </span>{" "}
                    Ireland holds the 11th position in the 2018 Global
                    Innovation Index{" "}
                  </p>
                </div>
                <div
                  class="tab-pane fade p-4 "
                  id="tab-contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Popular Courses to Study in the Ireland
                  </h4>
                  <div className="d-flex flex-row align-items-center justify-content-between gap-5">
                    <div className="d-flex flex-column ">
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        MS and M.Sc. in Data Analytics{" "}
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>
                        M.Sc. in Big Data Management
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        MEng in Digital and Medical Technologies{" "}
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        MS and M.Sc. in Computer Science and Masters in
                        Education{" "}
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        Occupational Therapy - Masters
                      </p>
                    </div>
                    <div className="d-flex flex-column ">
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        European Studies - Masters
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>
                        Exercise Physiology - Masters
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        Applied Behavior Analysis - Masters
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        Community Health - Masters{" "}
                      </p>
                      <p
                        class=" fw-semibold"
                        style={{ color: "#0f2239", textAlign: "justify" }}
                      >
                        {" "}
                        <span className="pe-2" style={{ color: "#fe5722" }}>
                          <FaCircleCheck />
                        </span>{" "}
                        Electronics Engineering - Masters{" "}
                      </p>
                    </div>
                    <div className="d-flex flex-column "></div>
                  </div>
                </div>
                <div
                  class="tab-pane fade p-4 "
                  id="about-contact"
                  role="tabpanel"
                  aria-labelledby="about-tab"
                >
                  <h4 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Cost of Living and Studying in the Ireland
                  </h4>
                  <h5 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Yearly Fees for Studying in TheIreland
                  </h5>

                  <table class="table table-responsive  table-bordered">
                    <tbody>
                      <tr style={{ backgroundColor: "#FF9993" }}>
                        <th scope="col">Courses</th>
                        <th scope="col">Cost</th>
                      </tr>

                      <tr>
                        <td>Undergraduate</td>
                        <td>9,850 EUR - 25,500 EUR</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Postgraduate</td>
                        <td>9,500 EUR - 34,500 EUR</td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 class="fw-bold py-2" style={{ color: "#0f2239" }}>
                    Monthly Expenses of Staying in The Ireland
                  </h5>

                  <table class="table table-responsive  table-bordered">
                    <tbody>
                      <tr style={{ backgroundColor: "#FF9993" }}>
                        <th scope="col">Living Expenses</th>
                        <th scope="col">Cost</th>
                      </tr>

                      <tr>
                        <td>Rent</td>
                        <td>400 EUR - 650 EUR</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Transportation</td>
                        <td>65 EUR - 85 EUR</td>
                      </tr>
                      <tr>
                        <td>Food</td>
                        <td>250 EUR - 375 EUR</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Study Material</td>
                        <td>70 EUR - 75 EUR</td>
                      </tr>
                      <tr>
                        <td>Telephone and Internet</td>
                        <td>20 EUR - 35 EUR</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FF9993", color: "#fff" }}>
                        <td>Health Insurance</td>
                        <td>41 EUR - 50 EUR</td>
                      </tr>
                      <tr>
                        <td>Miscellaneous Expenses</td>
                        <td>200 EUR - 300 EUR</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <ul class="list-group list-group-flush border rounded-2 p-4">
              <h4 className="text-center py-2">Countries</h4>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-USA"
                  className="text-decoration-none "
                  style={{ color: "#fe5722" }}
                >
                  Study in USA
                </Link>
              </li>
              
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-UK"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in UK
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Canada"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Canada
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Australia"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Australia
                </Link>
              </li>
              <li
                class="list-group-item  active border-0 py-3"
                style={{ backgroundColor: "#fe5722" }}
              >
                <Link
                  to="/Study-In-Ireland"
                  className="text-decoration-none text-white"
                  style={{ backgroundColor: "#fe5722" }}
                >
                  Study in Ireland
                </Link>
              </li>
             
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-New-Zealand"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in New Zealand
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Europe"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Europe
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-France"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in France
                </Link>
              </li>
              <li class="list-group-item py-3">
                <Link
                  to="/Study-In-Signapore"
                  className="text-decoration-none"
                  style={{ color: "#fe5722" }}
                >
                  Study in Singapore
                </Link>
              </li>
            </ul>
            <StudentEnquiry />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <h4
            className="text-center fw-bold text-uppercase"
            style={{ color: "#fe5722" }}
          >
            RELATED COUNTRIES
          </h4>
          <h2 className="text-center fw-bold " style={{ color: "#0f2239" }}>
            {" "}
            <span style={{ fontWeight: "bolder" }}>O</span>ther{" "}
            <span style={{ fontWeight: "bolder" }}>S</span>tudy{" "}
            <span style={{ fontWeight: "bolder" }}>B</span>road{" "}
            <span style={{ fontWeight: "bolder" }}>D</span>estination
          </h2>

          <section class="slide-option my-5">
            <div id="infinite" class="highway-slider">
              <div class="container highway-barrier">
                <ul class="highway-lane">
                  <li class="highway-car">
                    <div
                      class="card shadow rounded-0"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://adm.dookinternational.com/dook/images/country/XYwKYrjh1656324286.jpg"
                        class="card-img-top rounded-0 h-100 "
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in UK</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.commonwealthfund.org/sites/default/files/styles/countries_hero_desktop/public/country_image_Canada.jpg?h=f2fcf546&itok=tfGdKLUg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Canada</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.cnn.com/api/v1/images/stellar/prod/191011110307-02-australia-beautiful-places.jpg?q=w_1110,c_fill"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Australia
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.timeout.com/images/105230283/750/422/image.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Ireland</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.citigroup.com/rcs/v1/siteIds/citigpa/asset/63895c15d2b14b2b67154008.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in New Zealand
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://t4.ftcdn.net/jpg/02/94/27/73/360_F_294277354_ev3qw00wjlHAfhqRdEozsrVRpbhixC3S.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Singapore
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDIRg4bzPx84UPDDUW5o9bmWVTKxguqDyD3A&s"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in France</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.visa.co.uk/dam/VCOM/regional/ve/unitedkingdom/in-page-images/visa-in-europe/visa-eu-header-france-1600x900.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Europe</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="highway-lane">
                  <li class="highway-car">
                    <div
                      class="card shadow rounded-0"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://adm.dookinternational.com/dook/images/country/XYwKYrjh1656324286.jpg"
                        class="card-img-top rounded-0 h-100 "
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in UK</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.commonwealthfund.org/sites/default/files/styles/countries_hero_desktop/public/country_image_Canada.jpg?h=f2fcf546&itok=tfGdKLUg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Canada</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.cnn.com/api/v1/images/stellar/prod/191011110307-02-australia-beautiful-places.jpg?q=w_1110,c_fill"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Australia
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://media.timeout.com/images/105230283/750/422/image.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Ireland</h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.citigroup.com/rcs/v1/siteIds/citigpa/asset/63895c15d2b14b2b67154008.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in New Zealand
                        </h5>

                        <Link
                          to=""
                          class="btn mx-auto d-block btn-sm text-uppercase fw-semibold px-4 py-2 "
                          style={{
                            backgroundColor: "#fe5722",
                            color: " #fff",
                            fontSize: "12px",
                          }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://t4.ftcdn.net/jpg/02/94/27/73/360_F_294277354_ev3qw00wjlHAfhqRdEozsrVRpbhixC3S.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">
                          Study in Singapore
                        </h5>

                        <Link
                          to="#"
                          class="btn"
                          style={{ backgroundColor: "#fe5722", color: " #fff" }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDIRg4bzPx84UPDDUW5o9bmWVTKxguqDyD3A&s"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in France</h5>

                        <Link
                          to="#"
                          class="btn"
                          style={{ backgroundColor: "#fe5722", color: " #fff" }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li class="highway-car">
                    <div
                      class="card shadow"
                      style={{ width: "18rem", height: "20rem" }}
                    >
                      <img
                        src="https://www.visa.co.uk/dam/VCOM/regional/ve/unitedkingdom/in-page-images/visa-in-europe/visa-eu-header-france-1600x900.jpg"
                        class="card-img-top rounded-0 h-100"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-center">Study in Europe</h5>

                        <Link
                          to="#"
                          class="btn"
                          style={{ backgroundColor: "#fe5722", color: " #fff" }}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Ireland;
